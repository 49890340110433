import { colegiaSSOLoginApi } from './Events';
import { clearUserData, hideLoader, showLoader } from './helpers'
import axios from 'axios'

async function sso_login(){
  showLoader();
  let payload = {
    mwsRequest: {
      String: {
        Request: {
          Session: {
            token: "NO-TOKEN",
          },
          Client: {
            platform: "web",
            identity: "",
            version: "1.0",
            session_hash: "",
          }
        
        },
      },
    },
  };

  try {         
    const response = await colegiaSSOLoginApi(payload)
    if(response){
      console.log(response)
      if(response.data.mwsResponse.Status.code === '000'){
          const redirect_url = response.data.mwsResponse.redirect_url
          window.open(redirect_url,'_self')
      }
      else{
          let error = response.data.mwsResponse.Status.message
          console.log(error)
      }
    }
    hideLoader();
  }
  catch(error){
    console.log(error)
    hideLoader();
  }
}

const axiosClient = axios.create()
// const getCSRFToken = async () => {
//   const response = await axiosClient.get('getCSRFToken');
 
// }
// axiosClient.defaults.headers['X-CSRF-Token'] = '1234'
 axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
    Accept: 'application/json'
    // 'X-CSRF-Token': response.data.mwsResponse.csrf_token
  }
axiosClient.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`


axiosClient.defaults.withCredentials = true
// getCSRFToken();
axiosClient.interceptors.response.use(function (response) {
 
  if (response.data.session != null && response.data.session.status == false){
     clearUserData();
     if(!(window.location.href.indexOf('admin') > -1)){
      localStorage.setItem('alaia_redirect_url',window.location.href)
     }
    const url = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const alaia_assignment_uuid = params.get('alaia_assignment_uuid')
    const colegia_assignment_id = params.get('colegia_assignment_id')

    if (alaia_assignment_uuid || colegia_assignment_id) {
      sso_login();
      return
    }
     window.location.href = `${process.env.REACT_APP_REDIRECT_PATH}`;
  }
  else{
  return response
  }
 
}, function (error) {
  if(error.response){
  if (error.response.status === 401) {
    // clearSession()
   
  }
}
  return Promise.reject(error)
})

export default axiosClient
