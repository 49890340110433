import { useNavigate } from 'react-router-dom';
import '../../../src/assets/stylesheets/shared/toasters.scss'
import { updateWebNotificationApi } from '../../services/Events';
import { getUserData } from '../../services/helpers'

export async function update_notification(type, setNotifications, notifications) {
    const userData = getUserData();
    const u_notification = notifications?.find(notification => notification.type === type) || {}
    if(u_notification.status !== 'pending') return;

    setNotifications((previousInfo) => 
        previousInfo.map((notification) =>
          notification.type === type
            ? { ...notification, status: 'completed' }
            : notification
        )
    );

    let payload = { mwsRequest:{ String: 
        {Request:
        {   Session: { token: '' ,loggedin_user_type: userData.user_info.user_type},
            Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
            ManagedUser: {uuid: userData.user_info.user_account_uuid},
            Notification: {type: type}
        }}}}
        const response = await updateWebNotificationApi(payload)
        if(response){
            console.log(response)
            if(response.data.mwsResponse.Status.code === '000'){
                console.log("Notification disabled")
            }
            else{
                console.log(response)
            }
        }
} 
export const QueueSuccessToast = ({setNotifications, notifications}) =>{
    const navigate = useNavigate();

    return <>
        <div className='toaster_wrapper'>
            <div className='toaster_close_button_container'><span className='close_button' onClick={()=>{
                update_notification('queue',setNotifications, notifications)
            }}></span></div>
            <div className='toaster_container'>
                <div className='toaster_message_container'>
                    <div className='toaster_message_left'>
                        <div className='icn_container queue'></div>
                    </div>
                    <div className='toaster_message_right'>
                        <h4 className='toaster_message_header'>Audio Generated</h4>
                        <h6 className='toaster_message_subheader'>Your audio has been generated <br/>successfully</h6>
                    </div>
                </div>
                <div className='toaster_button_container'>
                    <button className='toaster_button'onClick={()=>{
                        navigate('/custom-exercise/list')
                        update_notification('queue',setNotifications, notifications)
                    }}>Go to Exercise</button>
                </div>
            </div>
        </div>
    </>
}

export const FeedbackMarkedPending = () =>{
    return <>
        <div className='toaster_wrapper feedback'>
            <div className='toaster_container'>
                <div className='toaster_message_container'>
                    <div className='toaster_message_left'>
                        <div className='icn_container feedback_action'></div>
                    </div>
                    <div className='toaster_message_right'>
                        <h4 className='toaster_message_header'>Marked as Pending</h4>
                        <h6 className='toaster_message_subheader'>Feedback has been marked as pending successfully</h6>
                    </div>
                </div>
            </div>
        </div>
    </>
}


// export const FeedbackSolved = () =>{
//     return <>
//         <div className='toaster_wrapper feedback'>
//             <div className='toaster_container'>
//                 <div className='toaster_message_container'>
//                     <div className='toaster_message_left'>
//                         <div className='icn_container feedback'></div>
//                     </div>
//                     <div className='toaster_message_right'>
//                         <h4 className='toaster_message_header'>Marked as Pending</h4>
//                         <h6 className='toaster_message_subheader'>Feedback has been marked as pending successfully</h6>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
// }


export const FeedbackDeleted = () =>{
    return <>
        <div className='toaster_wrapper feedback'>
            <div className='toaster_container'>
                <div className='toaster_message_container'>
                    <div className='toaster_message_left'>
                        <div className='icn_container feedback_action'></div>
                    </div>
                    <div className='toaster_message_right'>
                        <h4 className='toaster_message_header'>Deleted successfully</h4>
                        <h6 className='toaster_message_subheader'>Feedback has been deleted successfully</h6>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export const FeedbackSuccessToast = () =>{
    return <>
        <div className='toaster_wrapper feedback'>
            <div className='toaster_container'>
                <div className='toaster_message_container'>
                    <div className='toaster_message_left'>
                        <div className='icn_container feedback'></div>
                    </div>
                    <div className='toaster_message_right'>
                        <h4 className='toaster_message_header'>Feedback sent</h4>
                        <h6 className='toaster_message_subheader'>Thank you for your feedback! Your message has been received and our <br/> team will review it shortly. We <br/> appreciate your input and support in helping us improve.</h6>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export const RateLimitToast = ({setNotifications, notifications}) =>{

    return <>
        <div className='toaster_wrapper'>
            <div className='toaster_close_button_container'><span className='close_button' onClick={()=>{
                update_notification('rate_limit',setNotifications, notifications)
            }}></span></div>
            <div className='toaster_container'>
                <div className='toaster_message_container'>
                    <div className='toaster_message_left'>
                        <div className='icn_container rate_limit'></div>
                    </div>
                    <div className='toaster_message_right'>
                        <h4 className='toaster_message_header'>Generate Audio Available</h4>
                        <h6 className='toaster_message_subheader'>Your daily credits for audio generation <br/> have been reset</h6>
                    </div>
                </div>
            </div>
        </div>
    </>
}