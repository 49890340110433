import {
  getDate,
  format,
  addDays,
  startOfWeek,
  startOfDay,
  isSameDay,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isAfter,
  set,
  getDaysInMonth,
  toDate,
} from "date-fns";
import moment from 'moment';
import { utcToZonedTime } from "date-fns-tz";
import $ from 'jquery'


export function validateAndUpdateFiles(files, options) {
  // check if the provided count prop is less than uploaded count of files
  if (options.count && options.count < files.length) {
    console.log(
      `Only ${options.count} file${
        options.count !== 1 ? "s" : ""
      } can be uploaded at a time`
    );
    return { status: false, code: "too_many_files" };
  }

  // check if some uploaded file is not in one of the allowed formats
  if (
    options.formats &&
    files.some(
      (file) =>
        !options.formats.some((format) =>
          file.name.toLowerCase().endsWith(format.toLowerCase())
        )
    )
  ) {
    console.log(
      `Only following file formats are acceptable: ${options.formats.join(
        ", "
      )}`
    );
    return { status: false, code: "format_not_allowed" };
  }

  // check if some uploaded file size is within limit
  if (
    options.sizeLimit &&
    files.some(
      (file) =>
        !options.formats.some((format) => file.size <= options.sizeLimit)
    )
  ) {
    console.log("File size limit exceeded");
    return { status: false, code: "size_exceeded" };
  }

  return { status: true };
}

export function extractContent(s) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function titleCase(string, separator = " ") {
  if (!string) return "";

  try {
    return string
      .trim()
      .replace(/_/g, " ")
      .split(separator)
      .map(
        (word) => word[0] && word[0].toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  } catch (error) {
    console.log(error, string);
    return "";
  }
}

// start for ciruclar progress loader
export function polarToCartesian(cx, cy, r, ad) {
  const ar = ((ad - 90) * Math.PI) / 180.0;

  return {
    x: cx + r * Math.cos(ar),
    y: cy + r * Math.sin(ar),
  };
}

export function describeArc(x, y, r, sa, ea) {
  const start = polarToCartesian(x, y, r, ea);
  const end = polarToCartesian(x, y, r, sa);

  const largeArcFlag = ea - sa <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    r,
    r,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

export function circularProgressSvg(
  x,
  y,
  r,
  progress = 0.1,
  viewBox = "0 0 100 100"
) {
  const percentCompleted = progress * 360 - 1;
  return (
    <div className="uploader_wrapper">
      <div className="uploader_container">
        <svg viewBox={viewBox}>
          <circle className="loader_bar" cx={x} cy={y} r={r}></circle>
          <path
            className="loader"
            d={describeArc(x, x, x - 10, 0, percentCompleted)}
          ></path>
        </svg>
      </div>
    </div>
  );
}

export function jsSlice(obj, ...args) {
  return { ...args.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}) };
}

export function dateToLocalTzString(date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}

Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

export const saveUserData = (userData) => {
  localStorage.setItem("alaiaSessionData", JSON.stringify(userData));
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem("alaiaSessionData") || "{}");
};

export const clearUserData = () => {
  // localStorage.removeItem('alaiaSessionData');
  // localStorage.removeItem('alaiafilters')
  // localStorage.removeItem('amb_volume')
  // localStorage.removeItem('search_key')
  localStorage.clear();
};

export const timestampToRuby = (ts) => {
  return parseInt(ts.toString().substring(0, 10));
};

export const currentTimeStamptoRuby = () => {
  const dateToday = new Date(); // Mon Jun 08 2020 16:47:55 GMT+0800 (China Standard Time)
  const timestamp = parseInt(Date.parse(dateToday).toString().substring(0, 10));
  return timestamp;
};

export const getratingTimestamp = (timezone, prevday = false) => {
  if (timezone == null) timezone = "UTC";
  const date = new Date();
  if (prevday) date.setDate(date.getDate() - 1);
  const zonedDate = dateWithTimeZone(timezone,date);
  return parseInt(Date.parse(zonedDate).toString().substring(0, 10));
};

export const getJournalTimestamp = (timezone, selectedDate) => {
  if (timezone == null) timezone = "UTC";
  const date = new Date();
  date.setDate(selectedDate.getDate());
  date.setMonth(selectedDate.getMonth());
  date.setFullYear(selectedDate.getFullYear());
  const zonedDate = dateWithTimeZone(timezone,date);
  return parseInt(Date.parse(zonedDate).toString().substring(0, 10));
};

export const formatAMPM = (date) =>{
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const convertTimestamptoDate = (timezone, timestamp) =>{
  if (timezone == null) timezone = "UTC";
  const givenDate = new Date(new Date(Number(timestamp + "000")).toLocaleString("en-US", { timeZone: timezone }));
  return formatAMPM(givenDate)
}

export const showPopup = (id, timer = null) => {
  if(timer){
    const loader = document.getElementById(id);
    loader.dataset.timerDuration = timer;
    loader.style.display = "inline-block";
  }
  document.getElementById(id).style.display = "inline-block";
};

export const hidePopup = (id) => {
  document.getElementById(id).style.display = "none";
};

export const showLoader = () => {
  showPopup("alaia_loader");
};

export const hideLoader = () => {
  hidePopup("alaia_loader");
};

export const showLoaderCustomExercise = (timerDuration) => {
  showPopup("alaia_loader_custom_exercise", timerDuration);
}

export const hideLoaderCustomExercise = () => {
  hidePopup("alaia_loader_custom_exercise");
};

export const checkToday = (timestamp, timezone) => {
  if (timezone == null) timezone = "UTC";
  const givenDate = new Date(Number(timestamp + "000")).toDateString();
  const today = new Date(
    new Date().toLocaleString("en-US", { timeZone: timezone })
  );
  return givenDate == today;
};

export const getPreviousdate = (timezone) => {
  if (timezone == null) timezone = "UTC";
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const zonedDate = utcToZonedTime(date, timezone);
  return startOfDay(zonedDate);
};

export const getCurrentdate = (timezone) => {
  if (timezone == null) timezone = "UTC";
  const date = new Date();
  const zonedDate = utcToZonedTime(date, timezone);
  return startOfDay(zonedDate);
};

export const checkpromptStartTime = (prompt_frequency, timezone) => {
  if (timezone == null) timezone = "UTC";
  const today = new Date(
    new Date().toLocaleString("en-US", { timeZone: timezone })
  );
  const wday = today.getDay();
  let current_time =
    Number(today.getHours()) * 100 + Number(today.getMinutes());
  const start_time = prompt_frequency[`day_${wday}`].start_time;
  if (current_time >= 2400) current_time = current_time - 2400;
  return current_time >= Number(start_time);
};

export const checkAnsweredforday = (timestamp, timezone) =>{
  if (timezone == null) timezone = "UTC";
  const date = new Date(
    new Date().toLocaleString("en-US", { timeZone: timezone })
  );
  const start_date = utcToZonedTime(date, timezone);
  const end_date = utcToZonedTime(date, timezone);
  start_date.setHours(0, 0, 0, 0);
  const start =  parseInt(Date.parse(start_date).toString().substring(0, 10));
  end_date.setHours(23,59,59,999)
  const end =  parseInt(Date.parse(end_date).toString().substring(0, 10));
  return timestamp >= start && timestamp <= end
}

export const timesofDay = (timezone) => {
  if (timezone == null) timezone = "UTC";
  const today = new Date().toLocaleString("en-US", { timeZone: timezone });
  const todayDate = new Date(today);

  const curHr = todayDate.getHours();

  if (curHr < 12) {
    return "Morning";
  } else if (curHr < 18) {
    return "Afternoon";
  } else {
    return "Evening";
  }
};

export const timestampToJS = (ts) => {
  return parseInt(ts.toString().padEnd(13, '0'));
}


export const getDateInFormat = (timestampOrDate, format , type = 'timestamp' , timezone = null ) => {
  let dateObject;
  if (timezone ) {
  if (type === 'today') {
    dateObject =  new Date().toLocaleString("en-US", {timeZone: timezone})
  } else {
    timestampOrDate = type === 'timestamp' ? timestampToJS(timestampOrDate) : timestampOrDate;
    dateObject = new Date(timestampOrDate).toLocaleString("en-US", {timeZone: timezone})
  }
}
else {
  if (type === 'today') {
    dateObject =  new Date() ;
  } else {
    timestampOrDate = type === 'timestamp' ? timestampToJS(timestampOrDate) : timestampOrDate;
    dateObject = new Date(timestampOrDate);
  }
}

  if (format === 'date') {
    format = 'DD MMM YYYY';
  } else if (format === 'time') {
    format = 'hh:mma';
  } else if (format === 'datetime') {
    format = 'DD MMM YYYY hh:mma';
  }
  return moment(dateObject).format(format);
}

export const setFilterData = (data) => {
  localStorage.setItem('filterData', JSON.stringify(data));
}

export const getFilterData = () => {
  return (
    localStorage.filterData ? JSON.parse(localStorage.filterData) : null
  )
}

export const capitalizeString = (string) => {
  if (string == null) string = "";
  const str = string.toLowerCase();
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

export const capitalizeStringSpecialCharacter = (string) =>{
  string = string.replace(new RegExp("(?:\\b|_)([a-z])", "g"), function($1) {
    return $1.toUpperCase();
  });
return string
}

export const capitalizeUnitGradeString = (string) =>{
  if (string == null)
  string = ''
  const str = string;
  const arr = str.split("_");
  for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }
  const str2 = arr.join(" ");
  return str2
}

export const compareExcludeKeys = (object1, object2, excludeKeys = []) => {
  if(object1 && object2){
    if (Object.keys(object1).length !== Object.keys(object2).length) return false;
    return Object.entries(object1).reduce((isEqual, [key, value]) => {
      const isValueEqual =
        typeof value === "object" && value !== null
          ? compareExcludeKeys(value, object2[key], excludeKeys)
          : excludeKeys.includes(key) || object2[key] === value;
      return isEqual && isValueEqual;
    }, true);
  }
};

export const getYearFormat = (year, month, date, hour, minute) => {
  const fullyear =
    String(year) +
    "-" +
    String(month + 1).padStart(2, "0") +
    "-" +
    String(date).padStart(2, "0") +
    "T";
  const fulltime =
    String(hour).padStart(2, "0") +
    ":" +
    String(minute).padStart(2, "0") +
    ":00";
  return fullyear + fulltime;
};

export const CountdownTimer = (prompt_frequency, timezone) => {
  if (timezone == null) timezone = "UTC";
  const today = new Date(
    new Date().toLocaleString("en-US", { timeZone: timezone })
  );
  const wday = today.getDay();
  let current_time = today.getTime();
  const start_time = String(
    prompt_frequency[`day_${wday}`].start_time
  ).padStart(4, "0");
  const start_time_format = getYearFormat(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    start_time.substring(0, 2),
    start_time.substring(2, 4)
  );
  const start_time_epoch = new Date(start_time_format).getTime();
  var timeInterval = start_time_epoch - current_time;
  var timer = "expired";
  if (timeInterval > 0) {
    var days = Math.floor(timeInterval / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeInterval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeInterval % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = String(
      Math.floor((timeInterval % (1000 * 60)) / 1000)
    ).padStart(2, "0");

    if (hours > 0) {
      timer =
        minutes < 10
          ? `${hours}:0${minutes}:${seconds}`
          : `${hours}:${minutes}:${seconds}`;
    } else if (hours == 0 && minutes > 0) {
      timer = `${minutes}:${seconds}`;
    } else if (hours == 0 && minutes == 0) {
      timer = `00:${seconds}`;
    }
  }

  return timer;
};

export const getWeekDays = (timezone, gdate, add_day = 0) => {
  if (timezone == null) timezone = "UTC";
  const date = addDays(gdate, add_day);
  const zonedDate = utcToZonedTime(date, timezone);
  const start = startOfWeek(zonedDate, { weekStartsOn: 0 });
  const final = [];
  for (let i = 0; i < 7; i++) {
    const date = addDays(start, i);
    final.push({
      formatted: format(date, "EEEE").toUpperCase(),
      formatted_res: format(date, "EEE").toUpperCase(),
      date,
      day: getDate(date),
    });
  }
  return final;
};

export const getMonthDays = (timezone, g_date, add_day = 0) => {
  if (timezone == null) timezone = "UTC";
  const date = addDays(g_date, add_day);
  const zonedDate = utcToZonedTime(date, timezone);
  const start_of_month = startOfMonth(zonedDate);
  const end_of_month = endOfMonth(zonedDate);
  let final = [];
  for (let i = 0; ; i++) {
    const date = addDays(start_of_month, i);
    if (isAfter(date, end_of_month)) break;
    final.push({
      formatted: format(date, "EEEE").toUpperCase(),
      formatted_res: format(date, "EEE").toUpperCase(),
      date,
      day: getDate(date),
    });
  }
  const start_day = final[0].date.getDay();
  const end_day = final[final.length - 1].date.getDay();
  final = [...Array(7 - (7 - start_day)).fill(null), ...final];
  final = [...final, ...Array(7 - (1 + end_day)).fill(null)];
  return final;
};

export const checkSameDay = (selectedDate, weekDate) => {
  return isSameDay(selectedDate, weekDate);
};

export const dateWithTimeZone = (timeZone, t_date) => {
  // let date = new Date(Date.UTC(t_date.getFullYear(), t_date.getMonth(), t_date.getDate(),t_date.getHours(), t_date.getMinutes(), t_date.getSeconds()));
  // let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));;
  let tzDate = new Date(t_date.toISOString()); 
  // let offset = utcDate.getTime() - tzDate.getTime();
  // date.setTime( date.getTime() + offset );
  return tzDate;
};

export const getStartTimestamp = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  // const zonedDate = utcToZonedTime(date, timezone);
  const zonedDate = dateWithTimeZone(timezone, date)
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  return parseInt(Date.parse(start_date).toString().substring(0, 10));
};

export const getStartFormattedDate = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  return format(start_date, "yyyy-MM-dd HH:mm:ss");
};

export const getDateofStartWeek = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  return format(start_date, "yyyy-MM-dd HH:mm:ss");
};

export const getStartTimestampOfDay = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  zonedDate.setHours(0, 0, 0, 0);
  return parseInt(Date.parse(zonedDate).toString().substring(0, 10));
};

export const getEndTimestamp = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  const end_date = addDays(start_date, 6);
  end_date.setHours(23, 59, 59, 59);
  return parseInt(Date.parse(end_date).toString().substring(0, 10));
};

export const getEndFormattedDate = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  const end_date = addDays(start_date, 6);
  end_date.setHours(23, 59, 59, 59);
  return format(end_date, "yyyy-MM-dd HH:mm:ss");
};

export const getDateofEndWeek = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  const end_date = addDays(start_date, 6);
  end_date.setHours(23, 59, 59, 59);
  return format(end_date, "yyyy-MM-dd HH:mm:ss");
};

export const getStartTimestampMonth = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const start_date = startOfMonth(zonedDate);
  return parseInt(Date.parse(start_date).toString().substring(0, 10));
};

export const getStartDateofMonth = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const start_date = startOfMonth(zonedDate);
  return format(start_date, "yyyy-MM-dd HH:mm:ss");
};

export const getEndTimestampMonth = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const end_date = endOfMonth(zonedDate);
  end_date.setHours(23, 59, 59, 59);
  return parseInt(Date.parse(end_date).toString().substring(0, 10));
};

export const getEndDateofMonth = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const end_date = endOfMonth(zonedDate);
  end_date.setHours(23, 59, 59, 59);
  return format(end_date, "yyyy-MM-dd HH:mm:ss");
};

export const getEndTimestampOfDay = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = dateWithTimeZone(timezone, date);
  zonedDate.setHours(23, 59, 59, 59);
  return parseInt(Date.parse(zonedDate).toString().substring(0, 10));
};

export const getTimelist = () => {
  const result = [];
  const interval = 1;
  for (let i = 0; i < 60 * 12; i += interval) {
    let hours = Math.floor(i / 60);
    let minutes = i % 60;
    let time_hash = [];
    if (hours == 0) hours = 12;
    time_hash = {
      label: `${String(hours).padStart(2, 0)}:${String(minutes).padStart(
        2,
        0
      )}`,
      value: `${String(hours).padStart(2, 0)}:${String(minutes).padStart(
        2,
        0
      )}`,
    };
    result.push(time_hash);
  }
  return result;
};

export const getStartandEndDate = (timezone, g_date, add_day = 0) => {
  if (timezone == null) timezone = "UTC";
  const date = addDays(g_date, add_day);
  const zonedDate = dateWithTimeZone(timezone, date);
  const start = startOfWeek(zonedDate, { weekStartsOn: 0 });
  const end = endOfWeek(zonedDate, { weekStartsOn: 0 });
  return [start, end];
};

export const getRatingColor = (rating) => {
  if (rating != null) {
    rating = (Number(rating) / 5) * 100;
    if (rating < 30) return "#DB1C26";
    else if (rating >= 30 && rating <= 99) return "#FFBB00";
    else if (rating == 100) return "#00C717";
    else if (rating > 100) return "#008AFF";
  }
};

export const tconvert = (time) => {
  let hr = Number(time.slice(0, 2));
  let min = Number(time.slice(2, 4));
  let meridiem = "AM";
  if (hr >= 12) {
    meridiem = "PM";
    hr = hr > 12 ? hr - 12 : hr;
  }
  hr = hr == 0 ? 12 : hr;
  let ftime = `${String(hr).padStart(2, 0)}:${String(min).padStart(
    2,
    0
  )} ${meridiem}`;
  return ftime;
};

export const rconvert = (time) => {
  let hr = Number(time.slice(0, 2));
  let min = Number(time.slice(3, 5));
  let meridiem = time.slice(6, 8);
  if (meridiem == "AM") {
    hr = hr == 12 ? hr - 12 : hr;
  } else if (meridiem == "PM") {
    hr = hr != 12 ? hr + 12 : hr;
  }
  let ftime = `${String(hr).padStart(2, 0)}${String(min).padStart(2, 0)}`;
  return ftime;
};

export const mergeArray = (array1, array2) => {
  if (array1.length > 0 && array2.length == 0) return array1;
  else if (array1.length == 0 && array2.length > 0) return array2;
  else if (array1.length > 0 && array2.length > 0) {
    let arr = [];
    arr = array1.concat(array2);
    let unique = Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);

    return unique;
  } else return [];
};

export const setWeekSummary = (reflectHistory, selectedDate) => {
  let week_summary = [];
  if (reflectHistory.length > 0) {
    const start = startOfWeek(selectedDate, { weekStartsOn: 0 });
    for (let i = 0; i < 7; i++) {
      const date = addDays(start, i);
      const formatted_date = format(date, "MM/dd/Y");
      const r_day =
        reflectHistory.filter((rh) => rh.date == formatted_date) || [];
      if (r_day.length > 0) week_summary = [...week_summary, ...r_day];
    }
  }
  return week_summary;
};

export const setQWeekSummary = (reflectHistory, selectedDate) => {
  let week_summary = [];
  if (reflectHistory.length > 0) {
    const start = startOfWeek(selectedDate, { weekStartsOn: 0 });
    for (let i = 0; i < 7; i++) {
      const date = addDays(start, i);
      const formatted_date = format(date, "MM/dd/Y");
      const r_day =
        reflectHistory.find((rh) => rh.date == formatted_date) || {};
      if (Object.keys(r_day).length > 0) week_summary.push(r_day);
    }
  }
  return week_summary;
};

export const setQMonthSummary = (reflectHistory, selectedDate) => {
  let month_summary = [];
  if (reflectHistory.length > 0) {
    const start = startOfMonth(selectedDate);
    const no_of_days = getDaysInMonth(selectedDate);
    for (let i = 0; i < no_of_days; i++) {
      const date = addDays(start, i);
      const formatted_date = format(date, "MM/dd/Y");
      const r_day =
        reflectHistory.filter((rh) => rh.date == formatted_date) || [];
      if (r_day.length > 0) month_summary = [...month_summary, ...r_day];
    }
  }

  return month_summary;
};

export const getRating = (reflect_history) => {
  let rating = 0;
  for (let i = 0; i < reflect_history.length; i++) {
    rating += reflect_history[i].rating || reflect_history[i].average_rating;
  }
  return Math.round(rating / reflect_history.length);
};

export const getOverallSummary = (r_history) => {
  const unique = [...new Set(r_history.map((item) => item.question_uuid))];
  const week_response = [];
  unique.map((q_uuid) => {
    const question = r_history.filter((rh) => rh.question_uuid == q_uuid);
    const q_rating = getRating(question);
    week_response.push({
      question_uuid: q_uuid,
      question: question[0].question,
      average_rating: q_rating,
    });
  });
  return week_response;
};

export const getStartofWeek = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  return start_date;
};

export const getEndofWeek = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const start_date = startOfWeek(zonedDate, { weekStartsOn: 0 });
  const end_date = addDays(start_date, 6);
  return end_date;
};

export const getStartofMonth = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const start_date = startOfMonth(zonedDate);
  return start_date;
};

export const getEndofMonth = (timezone, date) => {
  if (timezone == null) timezone = "UTC";
  const zonedDate = utcToZonedTime(date, timezone);
  const end_date = endOfMonth(zonedDate);
  return end_date;
};

export const getEnableCount = (Hsetting) => {
  let ecount = 0;
  for (let i = 0; i < 7; i++) {
    if (
      Hsetting.prompt_frequency[`day_${i}`].is_enabled == 1 ||
      Hsetting.prompt_frequency[`day_${i}`].is_enabled == true
    )
      ecount++;
  }
  return ecount;
};

export const isUrlValid = (url) =>{
  const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
  return regex.test(url)
}


export const getYears = (count = 100) =>{
  const curr_year = new Date().getFullYear() + 7;
  const year_arr = []
  for(let i =0 ;i< count+7; i++){
    year_arr.push(curr_year-i)
  }
  return year_arr
}

export const padTo2Digits = (num) =>{
  return num.toString().padStart(2, '0');
}

export const formatDateToDateTime = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

export const validate_infolder_name = (name,sub_folders=[]) =>{
  
  for (var i in sub_folders){
       if (sub_folders[i].name.toLowerCase() == name.toLowerCase())
       return false;
  }
  return true;
}

export const getURLParams = (url, key) => {
  const d_url = new URL(url);

  if(key == 'key_path'){
    const url_params = d_url?.search?.split('=');
    url_params.shift();
    const new_params = url_params.join('=')
    const params = new URLSearchParams(d_url.search);
    if(!params.has('type') && !params.has('alaia_assignment_uuid')){
      return new_params;
    }
    else{
      const only_keypath = new_params.split('&')
      only_keypath.pop();
      return only_keypath.join('&')
    }
  }
  return d_url.searchParams.get(key)
}

export const getGrade = (name) =>{
  if(name == "middle_school")
    return "Middle School"
  else if(name == "high_school")
    return "High School"
  return name
}

export const getGradeName = (name) =>{
  if(name == "Middle School")
    return "Grade 6-8";
  else if (name == "High School")
    return "Grade 9-12";
  else if (name == "k_5")
    return "K-5";

    return "";
}

export const getk5grades = (name) =>{
  if(name === "middle_school")
    return "Grade 6-8"
  else if(name === "high_school")
    return "Grade 9-12"
  else if(name == "kindergarten")
    return 'Kindergarten'
  else if(name == "grade_1")
    return 'Grade 1'
  else if(name == "grade_2")
    return 'Grade 2'
  else if(name == "grade_3")
    return 'Grade 3'
  else if(name == "grade_4")
    return 'Grade 4'
   else if(name == "grade_5")
    return 'Grade 5'


}

export const getDateText = (selectedDate, width) =>{
  
  if(selectedDate == null) return '';

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

  if(width >= 750)
    return `${days[selectedDate.getDay()]}, ${months[selectedDate.getMonth()]} ${selectedDate.getDate()}`
  else
    return `${days[selectedDate.getDay()].slice(0,3)}, ${months[selectedDate.getMonth()].slice(0,3)} ${selectedDate.getDate()}`
}

export const validateFileName = (fileArray=[], fileName) =>{

  for(let i=0; i<fileArray.length; i++){

    if(fileArray[i].toLowerCase().trim() === fileName.toLowerCase().trim()){
      return false;
    }
  }
  return true

}

export const formatCustomExcDate = (created_timestamp, timezone) => {
  // Convert epoch timestamp to milliseconds
  const timestamp_ms = created_timestamp * 1000;

  // Format the date into the desired format in the specified timezone
  const formatted_date = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }).format(new Date(timestamp_ms));

  return formatted_date;
}

export const getAgeInfo = () =>{

  const userInfo = getUserData()?.user_info || {};
  let ageRange;

  const gradeToAge = {
    1: '6 to 7',
    2: '7 to 8',
    3: '8 to 9',
    4: '9 to 10',
    5: '10 to 11',
    6: '11 to 12',
    7: '12 to 13',
    8: '13 to 14',
    9: '14 to 15',
    10: '15 to 16',
    11: '16 to 17',
    12: '17 to 18'
  };

  if (userInfo.grade) {
    const grade = parseInt(userInfo.grade);
    if (!isNaN(grade) && grade >= 1 && grade <= 12) {
      return gradeToAge[grade];
    }
  } 
  else if (userInfo.age && !isNaN(userInfo.age) && userInfo.age >= 2 && userInfo.age <= 18) {
    const age = parseInt(userInfo.age);
    ageRange = `${age - 1} to ${age + 1}`;
    return ageRange
  }

  return 'adults';
}
export const convertToFormData = (payload) => {
  const formData = new FormData();

  const flattenObject = (obj, prefix = '') => {
    const flatObject = {};
  
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        const value = obj[key];
  
        if (value instanceof FormData) {
          // Handle FormData separately
          for (let [nestedKey, nestedValue] of value.entries()) {
            const nestedPropName = prefix ? `${prefix}[${nestedKey}]` : nestedKey;
            flatObject[nestedPropName] = nestedValue;
          }
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          // Recursively flatten nested objects, excluding voice_data if it's FormData
          if (!(value instanceof FormData && key === 'voice_data')) {
            const nestedFlatObject = flattenObject(value, propName);
            Object.assign(flatObject, nestedFlatObject);
          }
        } else {
        
          flatObject[propName] = value;
        }
      }
    }
  
    return flatObject;
  };
  

  const flattenedPayload = flattenObject(payload);
  console.log(flattenedPayload)
 
  for (let key in flattenedPayload) {
    if (flattenedPayload.hasOwnProperty(key)) {
      const value = flattenedPayload[key];
      formData.append(key, value);
    }
  }

  return formData;
};


export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};