import { useState } from 'react';
import { addFeedbackApi } from '../../services/Events';
import { FeedbackSuccessToast } from './Toasters';
import { getUserData } from '../../services/helpers';
import ErrorMsg from './ErrorMsg';
import '../../../src/assets/stylesheets/_feedbacks.scss';

export default function Feedback() {

    const userInfo = getUserData();
    const [feedbackState, setFeedbackState] = useState('default')
    const [feedbackSubject, setFeedbackSubject] = useState('')
    const [feedbackContent, setFeedbackContent] = useState('')
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [err, setErr] = useState('');
    const [errFlag, setErrFlag] = useState(false); 

    const [errorHash, setErrorHash] = useState({
        subject: false,
        content: false
      })
    
    const validateInputs = () => {
        const errors = {
            subject: false,
            content: false
        };
        
        if(!feedbackSubject) errors.subject = true;
        if(!feedbackContent || (feedbackContent && feedbackContent.length > 500)) errors.content = true;
        
        const hasErrors = Object.values(errors).includes(true);
        console.log(errors)
        
        setErrorHash({...errors});
        
        return hasErrors
    };

    function handleFeedbackClick() {
        if (feedbackState === 'default')
            setFeedbackState('clicked')
        else if  (feedbackState === 'clicked')
            setFeedbackState('opened')
    }
    
    async function handleFeedbackSend() {
        if(validateInputs()){ return; }

        let feedbackLocation = window.location.href;
        let payload = 
        { mwsRequest:{ String: 
            {Request:
            { Session: { token: '' ,loggedin_user_type: userInfo.user_info.user_type},
            Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
            Api: {version: '1.0'},
            Feedback: {feedback_subject: feedbackSubject, feedback_content: feedbackContent, feedback_location: feedbackLocation, status: 'pending'},
            ManagedUser: {uuid: userInfo.user_info.user_account_uuid, email: userInfo.user_info.email}
        }}}}
        
        console.log('payload', payload);
        
        try {
            const res = await addFeedbackApi(payload);
        
            if (res) {
            console.log("res", res);
            if (res.data?.mwsResponse?.Status?.code === '000') {
                setFeedbackState('default');
                setFeedbackSubject('');
                setFeedbackContent('');
                setFeedbackSent(true);
                setTimeout(()=>{
                  setFeedbackSent(false)
                },5000)
            } else {
                setErr(res.data?.mwsResponse?.Status?.message);
                setErrFlag(true);
            }
            }
        } catch (error) {
            console.error('Error in handleFeedbackSend:', error);
        }
    }
    
    async function setFeedbackToDefault() {
        setFeedbackState('default');
        setFeedbackContent('');
        setFeedbackSubject('');
        setErrorHash({subject: false, content: false})
    }

    return <>
      {feedbackSent && <FeedbackSuccessToast/>}
        {
            feedbackState !== 'opened'  ?
            <div className={`feedback_container ${feedbackState}`}
            // onClick={handleFeedbackClick()}
            onClick={() => { handleFeedbackClick(); } }
        > 
        <img className="feedback_icon"/> 
        {feedbackState === 'default' ? 
            'Feedback' :                 
            'Did you see something wrong? Feedback'} </div>
            :
            <div className={`feedback_container ${feedbackState}`}> 
            <div className="feedback_header_container">
                <img className="feedback_icon"></img>
                <div className="feedback_header_text"> Feedback</div>
                <div className="feedback_header_close_btn" onClick={()=>setFeedbackToDefault()}></div>
            </div>
            {/* <div className={`feedback_header ${feedbackState}`}> Feedback  </div> */}
            <div className='feedback_input_container'>
                <div className="signin_container">
                    {errFlag && <ErrorMsg err={err}/>}
                    <div className="input_container">
                        <div className="feedback_input_title">Feedback Subject</div>
                        <input type="text" className="feedback_input subject" style={errorHash.subject ? {marginBottom: '32px'} : {}} placeholder='Type Something' value={feedbackSubject} onChange={(e)=>{setFeedbackSubject(e.target.value)}}></input>
                        {errorHash.subject && <div className="error_msg"> Enter a valid Subject</div>}
                    </div>
                    <div className="input_container">
                        <div className="feedback_input_title">Feedback</div>
                        <textarea type="textarea" className="feedback_input content"  style={errorHash.content ? {marginBottom: '32px'} : {}} placeholder='Type here...' value={feedbackContent} onChange={(e)=>{setFeedbackContent(e.target.value)}}></textarea>
                        {<div className="feedback_text_length" style={errorHash.content ? {bottom: '40px'} : {}}> {feedbackContent ? feedbackContent.length : '0'}/500 </div>}
                        {errorHash.content && <div className="error_msg"> Enter a valid Feedback </div>}
                    </div>
                </div>
                <p className='feedback_message'>Our team will review this and work to resolve it promptly. For technical assistance, please contact our  <a href='mailto:help@alaiamind.com' className='content_mail'>Support team.</a></p>
            </div>
            <div className='feedback_button_container'> 
                <button className='option_button cancel' onClick={()=>setFeedbackToDefault()}>Cancel</button>
                <button className='option_button send' onClick={()=>{handleFeedbackSend()}}>Send</button>
            </div>
            </div>
        }
      </>
}