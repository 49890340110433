import '../../assets/stylesheets/shared/_common.scss'

function ErrorMsg({err}) {
  return (
    <div className='error_msg_container'>
      <p className='error_msg'><span>{err}</span></p>
    </div>
  )
}

export default ErrorMsg;
